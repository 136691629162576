import axios from 'axios';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DownloadZip() {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("accessToken");

  const handleDownload = async () => {
    try {
      setLoading(true);

      const apiUrl = 'https://api.devvixr.in/v2/tenant/mass-asset/asset/sampleDownload';
      
      const headers = {
        Authorization: `bearer:${token}`, // Use 'Bearer' for consistency
      };

      const response = await axios.post(apiUrl, null, {
        headers,
        responseType: 'blob',
      });

      if (response.status === 200) {
        const blob = new Blob([response.data]);
        const tempUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = tempUrl;
        link.download = 'sample-file.zip';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(tempUrl);

        setLoading(false);
        toast.success('Download completed!', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        console.error('Error downloading file:', response.statusText);
        toast.error('Failed to download file. Please try again.', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      toast.error('An unexpected error occurred. Please try again.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={handleDownload}
        disabled={loading}
        className="bg-default-button hover:bg-default-button-hover text-white font-bold py-2 px-4 rounded mr-4"
      >
        {loading ? 'Downloading...' : 'Download Sample ZIP File'}
      </button>
      <ToastContainer />
    </div>
  );
}

export default DownloadZip;
