import React, { useState, useEffect } from "react";
import { api2, setAccessToken } from "../api/axiosConfig";
import { FaUser } from "react-icons/fa";
import emailValidator from 'email-validator';
import PhoneInput from "react-phone-input-2";

const ProfilePage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
    },
    user_role: "",
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const endpoint = "/user/get";
      const fuid = localStorage.getItem("fuidId");
      const payload = { fuid: fuid };
      const accessToken = localStorage.getItem("accessToken");
      setAccessToken(accessToken);

      try {
        const response = await api2.post(endpoint, payload);
        console.log(response.data.data);
        const { name, email, phone, address, icon, user_role } =
          response.data.data;
        const { line1, line2, city, state, country, zip_code } = address;

        setFormData((prevData) => ({
          ...prevData,
          name,
          email,
          phone,
          address: {
            ...prevData.address,
            line1,
            line2,
            city,
            state,
            country,
            zip_code,
          },
          user_role,
        }));
        setImage(icon);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleCancel = () => {
    console.log("Cancel button clicked!");
    setIsEditMode(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: {
        ...prevFormData.address,
        [name]: value,
      },
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await api2.post(`/user/update`, formData);
        console.log("Profile updated successfully:", response.data);
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    } else {
      console.log("Form validation failed");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    const nameRegex = /^[A-Za-z\s]+$/;
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    const phoneRegex = /^\+91[1-9]\d{9}$/;
    const allowedSpecialCharactersRegex = /^[a-zA-Z0-9\s\/.,-]*$/;
    const indiaPinCodeRegex = /^\d{6}$/;

    if (!formData.name?.trim() || !nameRegex.test(formData.name)) {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (!formData.email?.trim() || !emailValidator.validate(formData.email)) {
      newErrors.email = 'Email is required';
      valid = false;
    }

    if (!formData.phone?.trim() || !phoneRegex.test(formData.phone)) {
      newErrors.phone = "Phone is required";
      valid = false;
    }

    for (const [field, regex] of Object.entries({
      line1: allowedSpecialCharactersRegex,
      line2: allowedSpecialCharactersRegex,
      city: alphanumericRegex,
      state: alphanumericRegex,
      zip_code: indiaPinCodeRegex,
      country: alphanumericRegex,
    })) {
      if (
        !formData.address[field]?.trim() ||
        !regex.test(formData.address[field])
      ) {
        newErrors[field] = `${field} is required`;
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };
  const handleOnChange = (value, country, e, formattedValue) => {
    const { name} = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));
  };
  return (
    <div className="flex flex-col p-8 mt-5 pl-5 text-white mb-20 lg:mb-0">
      <div className="flex flex-col lg:flex-row md:flex-row">
        <h1 className="text-3xl mb-4 lg:mb-0">Personal Details </h1>
        {isEditMode ? (
          <div className="flex">
            <button
              className="ml-4 bg-grey-button text-white h-10 py-2 px-4 rounded"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="ml-4 bg-default-button text-white h-10 py-2 px-4 rounded"
              onClick={handleFormSubmit}
            >
              Save
            </button>
          </div>
        ) : (
          <button
            className="ml-4 bg-default-button text-white h-10 py-2 px-10 rounded"
            onClick={toggleEditMode}
          >
            Edit
          </button>
        )}
      </div>

      <div className="">
        <form>
          <div className="flex flex-col lg:flex-row md:flex-row items-start justify-start    mt-[34px]">
            <div className="bg-white w-12 h-12 rounded-full">
              {!image ? (
                <FaUser
                  size={30}
                  color={"grey"}
                  className="rounded-full w-full h-full object-cover"
                />
              ) : (
                <img
                  src={image}
                  alt="Uploaded"
                  className="rounded-full w-full h-full object-cover"
                />
              )}
              
            </div>

            <div className=" mt-4 lg:ml-[120px]">
              <input
                type="file"
                disabled={!isEditMode}
                className="font-inter w-full bg-gray_900  p-2 text-login-label  bg-transparent"
                id="profile_image_input"
                onChange={handleImageChange}
              />
            </div>
          </div>
          <div className="flex flex-row items-start justify-start mt-8">
            <p
              className="Label text-left uppercase mt-2 text-white w-20"
              style={{ fontSize: "14px", fontWeight: 400 }}
            >
              Phone number
            </p>
            <div className="ml-4">
            <PhoneInput
              country={"in"} // Default country
              value={formData.phone}
              onChange={handleOnChange}
              inputStyle={{ width: "100%", color:"black" }} // Optional: Adjust the input width
              dropdownStyle={{
    
                color:'black'
              }}
           />
              {/* <input
                disabled={!isEditMode}
                type="tel"
                className="font-inter w-full  p-2 text-login-label border-grey-button border-[1px] bg-white rounded"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              /> */}
            </div>
          </div>
          {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}
          <div className="flex flex-row items-start justify-start    mt-8">
            <p
              className="Label text-left uppercase mt-2 text-white w-20"
              style={{ fontSize: "14px", fontWeight: 400 }}
            >
              Name
            </p>

            <div className=" ml-4">
              <input
                disabled={!isEditMode}
                className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button  border-b-[1px] bg-transparent"
                type="text"
                id="name"
                name="name"
                placeholder="Enter Name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
          <div className="flex flex-row items-start justify-start    mt-8">
            <p
              className="Label text-left uppercase mt-5 text-white w-20"
              style={{ fontSize: "14px", fontWeight: 400 }}
            >
              Email
            </p>

            <div className=" ml-4">
              <input
                disabled={!isEditMode}
                className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                type="text"
                id="organization_name"
                name="email"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
          <div className="flex flex-row items-start justify-start    mt-8">
            <p
              className="Label text-left uppercase mt-5 text-white w-20"
              style={{ fontSize: "14px", fontWeight: 400 }}
            >
              Address
            </p>
          </div>
          <div className="flex flex-row items-start justify-start    mt-8">
            <p
              className="Label text-left uppercase mt-5 text-white w-20"
              style={{ fontSize: "14px", fontWeight: 400 }}
            >
              Line1
            </p>

            <div className=" ml-4">
              <input
                disabled={!isEditMode}
                className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px]  bg-transparent"
                type="text"
                id="line1"
                name="line1"
                placeholder="Enter Line 1"
                value={formData.address.line1}
                onChange={handleAddressInputChange}
              />
            </div>
          </div>
          {errors.line1 && <p style={{ color: "red" }}>{errors.line1}</p>}
          <div className="flex flex-row items-start justify-start    mt-8">
            <p
              className="Label text-left uppercase mt-5 text-white w-20"
              style={{ fontSize: "14px", fontWeight: 400 }}
            >
              Line2
            </p>

            <div className=" ml-4">
              <input
                disabled={!isEditMode}
                className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                type="text"
                id="line2"
                name="line2"
                placeholder="Enter Line 2"
                value={formData.address.line2}
                onChange={handleAddressInputChange}
              />
            </div>
          </div>
          {errors.line2 && <p style={{ color: "red" }}>{errors.line2}</p>}
          <div className="flex flex-col md:flex-row items-start justify-start mt-8">
            <div className="flex">
              <p
                className="Label text-left uppercase mt-5 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                Zip code
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label  border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  placeholder="Enter Zip Code"
                  value={formData.address.zip_code}
                  onChange={handleAddressInputChange}
                />
              </div>
            </div>
            {errors.zip_code && (
              <p style={{ color: "red" }}>{errors.zip_code}</p>
            )}
            <div className="flex mt-5 md:mt-0">
              <p
                className=" lg:ml-4 Label text-left uppercase mt-10 md:mt-5 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                City
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label  border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="city"
                  name="city"
                  placeholder="Enter City"
                  value={formData.address.city}
                  onChange={handleAddressInputChange}
                />
              </div>
            </div>
          </div>
          {errors.city && <p style={{ color: "red" }}>{errors.city}</p>}
          <div className="flex flex-col md:flex-row items-start justify-start    mt-8">
            <div className="flex">
              <p
                className=" Label text-left uppercase mt-5 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                State
              </p>

              <div className=" ml-4">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label  border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="state"
                  name="state"
                  placeholder="Enter State"
                  value={formData.address.state}
                  onChange={handleAddressInputChange}
                />
              </div>
            </div>
            {errors.state && <p style={{ color: "red" }}>{errors.state}</p>}
            <div className="flex mt-5 md:mt-0">
              <p
                className="lg:ml-4 Label text-left uppercase mt-5 text-white w-20"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                Country
              </p>

              <div className=" ml-4 mb-20 md:mb-0">
                <input
                  disabled={!isEditMode}
                  className="font-inter w-full bg-gray_900  p-2 text-login-label border-grey-button border-b-[1px] bg-transparent"
                  type="text"
                  id="country"
                  name="country"
                  placeholder="Enter Country"
                  value={formData.address.country}
                  onChange={handleAddressInputChange}
                />
              </div>
            </div>
            {errors.country && <p style={{ color: "red" }}>{errors.country}</p>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfilePage;
