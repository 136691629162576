import React from 'react';
import { FaUser } from 'react-icons/fa';

const Profile =  React.forwardRef(({ userName,onClick }, ref) => {
  return (
    <button  ref={ref} className="flex items-center bg-search-bar rounded-full  pl-5" onClick={onClick}>
      <div className="mr-5">
        <div className="font-semibold text-gray-300">{userName}</div>
      </div>
      <div className="flex items-center justify-center rounded-full bg-dim-grey w-10 h-10">
      <FaUser className="text-3xl text-gray-500" />
      </div>
    </button>
  );
});

export default Profile;
