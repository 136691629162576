// NotificationModal.js
import React, { useEffect, useState } from 'react';
import { FaAddressBook, FaSignOutAlt, FaShieldAlt, FaFileAlt , FaUser} from 'react-icons/fa';


const ProfileModal = ({ isOpen, onClose }) => {

const[name,setName]=useState('');
const[email,setEmail]=useState('');

useEffect(()=>{
setName(localStorage.getItem("loginUserName"));
setEmail(localStorage.getItem("loginUserEmail"));
},[])

  if (!isOpen) {
    return null;
  }

  return (
    <div
      
      className="fixed w-80 bg-header-bar rounded-xl shadow-md text-logo-color border border-search-bar z-50"
      style={{ width:'400px',maxHeight: '500px', top:'80px',right: '20px', overflowY: 'hidden' }}
    >
    
      <div className="flex flex-col items-center mt-4">
        <div className="w-24 h-24 rounded-full bg-notification-label flex items-center justify-center text-white text-2xl">
        <FaUser className="text-4xl text-gray-500" />
        </div>
        <div className="mt-4 text-xl text-notification-label">{name}</div>
        <div className="text-gray-600">{email}</div>
        <a href="/dashboard/user-profile" className="mt-4 py-2 px-6 bg-search-bar text-white rounded-full text-center text-sm hover:bg-right-panel">
          Manage Your ViXR Account
        </a>
      </div>
      <hr className="my-8 border-t border-search-bar" />
      <div className="flex justify-center">
        <ActionButton icon={<FaAddressBook />} text="Contacts" />
        <ActionButton icon={<FaSignOutAlt />} text="Sign Out" />
        </div>
        
        <div className="flex flex-row items-center justify-center mt-2 text-grey-label text-sm">
        <div className="flex flex-row items-center mr-2 ">
        <div><FaShieldAlt /></div><div>Privacy Policy</div>
      </div>
      <div className="flex flex-row items-center mr-2 ">
        <div><FaFileAlt /></div><div>Terms of Use</div>
      </div>
      </div>
      
     
      <div className="text-gray-500 text-center text-sm mt-2 pb-2">&copy;2021 - 2023 ViXR Inc.</div>
   
  
     
    </div>
  );
};
const ActionButton = ({ icon, text }) => {
    return (
        <a href="#" className="flex mt-4 items-center mr-2 py-2 px-6 bg-search-bar text-white rounded-full text-center text-sm hover:bg-right-panel">
            <span>{icon}</span>
        <span className="mt-1">{text}</span>
        </a>
    
    );
  };
export default ProfileModal;
