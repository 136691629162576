import React, { useEffect, useState } from "react";
import { api2, setAccessToken } from "../api/axiosConfig";
import toast from "react-hot-toast";
import DataDisplay from "../components/Util/DataDisplay";
import { BsGrid3X3, BsListUl } from 'react-icons/bs';

function PlatformAssets() {
  const [assets, setAssets] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isGrid, setIsGrid] = useState(false);
  
  
  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"));
    const fetchAssets = async () => {
      try {
        const response = await api2.post("/tenant/listPlatformResource");
        setAssets(response.data.data.platform_assets);
        console.log(response.data.data.platform_assets);
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    };

    const fetchOrganizations = async () => {
      try {
        const response = await api2.post("/tenant/list");
        setOrganizations(response.data.data.tenants);
        console.log(response.data.data.tenants);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    fetchAssets();
    fetchOrganizations();
  }, []);

  const handleRadioChange = (itemId) => {
    console.log(itemId);
    if (selectedValue === itemId) {
      // If the same radio button is clicked again, unselect it
      setSelectedValue(null);
    } else {
      // Otherwise, select the clicked radio button
      setSelectedValue(itemId);
    }
  };
  const handleCheckboxChange = (itemId) => {
    const isChecked = checkedItems.includes(itemId);

    if (isChecked) {
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
    } else {
      setCheckedItems([...checkedItems, itemId]);
    }
  };
  const toggleGridView = () => {
    setIsGrid(!isGrid);
  }; 
  const addAssetsToOrganization = async () => {
    const payload = {
      platform_assets: checkedItems,
      slug: selectedValue,
      platform_experiences: [],
    };

    try {
      const response = await api2.post("/tenant/upsertPlatformResource", payload);

      if (response.data.message === "success") {
        toast.success("Assets successfully added to organization");
      }

      console.log(response.data);
      setCheckedItems([]);
      setSelectedValue(null);
    } catch (error) {
      console.error("Error adding assets to organization:", error);
    }
  };

  const reloadFromS3 = async () => {
    try {
      const response = await api2.post("/platformAsset/reloadFromS3");

      if (response.data.message === "Successfully reload") {
        toast.success("Assets successfully reload");
      }

      console.log(response.data);
    } catch (error) {
      console.error("Error reloading from S3:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row lg:flex:row p-8 mt-5  text-center text-white items-center justify-center lg:justify-between">
        <h1 className="text-3xl mb-4 lg:mb-0">Platform Assets</h1>
        <div className="flex flex-col md:flex-row lg:flex-row">
          <button
            className="bg-default-button mb-4 lg:mb-0 text-white py-2 px-4 rounded mr-4"
            onClick={addAssetsToOrganization}
          >
            Add Assets to Organization
          </button>
          <button className="bg-default-button text-white py-2 px-4 rounded mr-4" onClick={reloadFromS3}>
            Reload From S3
          </button>
        </div>
      </div>
      <div className="flex justify-end mr-12">
      <button onClick={toggleGridView}>
        {isGrid ? (<button onClick={toggleGridView} className="flex items-center space-x-2 p-2 border rounded bg-search-bar hover:bg-right-panel">
  <BsListUl className="text-3xl text-white" />
 
</button>) : (<button onClick={toggleGridView} className="flex items-center space-x-2 p-2 border rounded bg-search-bar hover:bg-right-panel">
  <BsGrid3X3 className="text-3xl text-white" />
 
</button>)}
      </button>
      </div>
      
      <div className="flex flex-col md:flex-row lg:flex-row text-white m-10 mb-20">
     
        <div className="w-3/6">
          <h2 className="text-xl  mb-4 text-logo-color">Available Platform Assets</h2>
          <DataDisplay 
      data={assets} 
      isGrid={isGrid} // Pass grid view state here
      handleCheckboxChange={handleCheckboxChange}
      checkedItems={checkedItems}
      selectedValue={null} />
        </div>
        <div className="w-3/6">
          <h2 className="text-xl mb-4 text-logo-color">Organizations</h2>
          <DataDisplay data={organizations}
      isGrid={isGrid} // Pass grid view state here
      handleRadioChange={handleRadioChange}
      selectedValue={selectedValue}
      />
        </div>
      </div>
     
    
    </>
  );
}

export default PlatformAssets;
