import React, { useEffect, useState } from "react";
import axios from "axios";
import DownloadZip from "../components/Util/DownloadZip";
import ScheduledUpload from "../components/Util/ScheduledUpload";
import "react-toastify/dist/ReactToastify.css";
import DragDropUpload from "../components/Util/DragDropUpload";
import Tabs from "../components/Util/Tabs";
import { ProgressProvider } from "../context/ProgressContext";

const MassAssets = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [jobsUploaded, setJobsUploaded] = useState([]);
  const [isLoading, setIsLoading]=useState(true);
  useEffect(() => {
    getJobList();
  }, []);
  useEffect(() => {
    console.log("Updated state:", jobsUploaded);
  }, [jobsUploaded]);
  useEffect(() => {
    getJobList();
  }, [filesUploaded]);
  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };
  const getJobList = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const apiUrl =
        "https://api.devvixr.in/v2/tenant/mass-asset/asset/listJobs";
      const response = await axios.post(apiUrl, null, {
        headers: {
          Authorization: `bearer:${token}`,
        },
      });

      if (response.status === 200) {
        console.log("Response data:", response.data); // Log the entire response for inspection
        console.log("Jobs in response:", response.data.data.jobs);
        setJobsUploaded(response.data.data.jobs);
      } else {
        console.error("Error retrieving jobs:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleUploadFile = (fileForUpload) => {
    // Handle the single file upload logic here
    console.log(fileForUpload);
  };

  const handleMultipleUploadFiles = (files) => {
    setSelectedFiles(files);
    console.log(files);
  };

  const handleUploadedFiles = (uploadedFiles) => {
    setFilesUploaded((prevFiles) => [...prevFiles, ...uploadedFiles]);
    setSelectedFiles([]);
  };
  const clearFilesUpload=()=>{
    setFilesUploaded([]);
    setSelectedFiles([]);
  }

  return (
    <ProgressProvider>
      <div className="container mx-auto my-8 p-4 mb-20">
        <div className="flex flex-col md:flex-row lg:flex-row  justify-end">
          <DownloadZip />
        </div>
        <div className="z-10 flex justify-end mt-10">
          <ScheduledUpload filesForUpload={selectedFiles} clearUploadedFiles={clearFilesUpload} stopLoading={stopLoading}/>
        </div>

        <DragDropUpload
          handleUploadFile={handleUploadFile}
          handleUploadedFiles={handleUploadedFiles}
          handleMultipleUploadFiles={handleMultipleUploadFiles}
          isLoading={isLoading}
          startLoading={startLoading}
          selectedFiles={selectedFiles}
        />
        <Tabs jobsData={jobsUploaded}/>
       
      </div>
      </ProgressProvider>
  );
};

export default MassAssets;
