import React from 'react';
import { FaBell } from 'react-icons/fa';

const NotificationBell = React.forwardRef(({ notificationCount,onHandleClick }, ref) => {
  return (
    <div ref={ref}  className="flex items-center mr-5">
    <button className="flex items-center justify-center rounded-full bg-dim-grey w-10 h-10" onClick={onHandleClick}>
      <FaBell className="text-2xl text-black" />
    </button>
   {notificationCount===0?'': <div className="rounded-full bg-badge-color w-6 h-6 text-white flex items-center justify-center ml-[-8px] -mt-2 -mr-2">
      {notificationCount}
    </div>}
  </div>
  );
});

export default NotificationBell;
