// NotificationModal.js
import React, { useEffect, useRef } from 'react';
import { AiOutlineClose, AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai';

const NotificationModal = ({ isOpen, onClose,onClearAll, notifications, bellRef }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen && bellRef.current && modalRef.current) {
      const bellRect = bellRef.current.getBoundingClientRect();
      modalRef.current.style.top = `${bellRect.bottom}px`;
      modalRef.current.style.left = `${bellRect.left - 120}px`; // Adjust the left position
    }
  }, [isOpen, bellRef]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={modalRef}
      className="fixed w-80 mt-4 bg-header-bar rounded-xl shadow-md text-logo-color border border-search-bar z-50"
      style={{ width:'400px' ,maxHeight: '500px', left: '-40px', overflowY: 'hidden' }}
    >
      {/* Fixed Header */}
      <div className="p-4 flex justify-between items-center">
        <div className="text-xl font-bold">Notifications</div>
        <AiOutlineClose className="cursor-pointer" onClick={onClose} />
      </div>

      {/* Scrollable Content */}
      <div className="p-4 "
       style={{ maxHeight: '300px', overflowY: 'auto' }}
      >
        {/* Notification Items */}
        {notifications.map((notification, index) => (
          <>
          <div key={notification.title} className="notification-item mb-4 p-4 flex items-center hover:bg-search-bar cursor-pointer">
            <div className="mr-4">
              <AiOutlineCalendar className="text-notification-label text-4xl bold"/>
            </div>
            <div>
              <div className="text-notification-label text-xl font-bold">{notification.title}</div>
              <div className="text-notification-label">{notification.description}</div>
              <div className="flex items-start text-gray-500">
                <AiOutlineClockCircle className="mr-1 mt-1" />
                <div className='text-sm'> {notification.date} {notification.time}</div>
              </div>
             
            </div>
          
          </div>
           <hr className="h-2 mb-4 mt-4 border-t border-logo-color " />
           </>
        ))}
      </div>
      {/* Footer */}
      <div className="p-4 flex justify-center items-center ">
        <button
          className="text-sm text-logo-color hover:underline cursor-pointer"
          onClick={onClearAll}
        >
          Clear All
        </button>
      </div>
    </div>
  );
};

export default NotificationModal;
