import React from 'react';


const DataDisplay = ({ data, isGrid, handleCheckboxChange, handleRadioChange, checkedItems, selectedValue }) => {

// Placeholder image URL
const placeholderImageUrl = 'https://via.placeholder.com/150';
  return (
    <div>
           {isGrid ? (
        <div className="flex flex-wrap z-20">
          {data.map((item) => (
            <div
            key={item.uuid}  
              className={`flex-shrink-0 mr-4 mb-4 grid-item ${
                (handleCheckboxChange && checkedItems.includes(item.uuid)) ||
                (handleRadioChange && selectedValue === item.id)
                  ? 'border-2 bg-search-bar rounded-xl' // Add a border for the selected item
                  : ''
              }`}
            >
              {/* Checkbox input (hidden) */}
              {handleCheckboxChange && (
                <div className="cursor-pointer relative">
                 
                  <input
                    type="checkbox"
                    id={`item-${item.uuid}`}
                    checked={checkedItems.includes(item.uuid)}
                    onChange={() => handleCheckboxChange(item.uuid)}
                    className="absolute h-6 w-6 opacity-0"
                  />
                  <label
                    htmlFor={`item-${item.uuid}`}
                    className="w-48 h-48 object-cover rounded-lg shadow-lg transition-transform transform hover:scale-105 cursor-pointer block"
                  >
                    <img
                      src={item.imageUrl || placeholderImageUrl}
                      alt={`Item ${item.uuid}`}
                      className="w-full h-full object-cover rounded-lg"
                    />
                    {/* Overlay for selected item */}
                {checkedItems.includes(item.uuid) && (
                <div className="absolute inset-0 bg-search-bar opacity-50 rounded-lg"></div>
              )}
                  </label>
                </div>
              )}
               {/* Radio button input (hidden) */}
               {handleRadioChange && (
                <div className="cursor-pointer relative">
  <label
    htmlFor={`item-${item.id}`}
    className={`w-48 h-48 object-cover rounded-lg shadow-lg transition-transform transform hover:scale-105 cursor-pointer block ${
      selectedValue === item.uuid ? 'border-2 border-blue-500' : ''
    }`}
  >
    <input
      type="radio"
      id={`item-${item.id}`}
      name="radioGroup"
      value={item.id}
      checked={selectedValue === item.id}
      onChange={() => {handleRadioChange(item.id)}}
      className="sr-only" // Hide the default radio button
    />
    <img
      src={item.imageUrl || placeholderImageUrl}
      alt={`Item ${item.id}`}
      className="w-full h-full object-cover rounded-lg"
    />
      {/* Overlay for selected item */}
      {selectedValue === item.id && (
                <div className="absolute inset-0 bg-search-bar opacity-50 rounded-lg"></div>
              )}
  </label>
</div>
)}
              {/* Item details */}
              <p className="mt-2 text-white text-lg font-semibold  max-w-[150px] whitespace-normal">{item.name}</p>
              <p className="text-white">{item.description}</p>
            </div>
          ))}
        </div>
      ) 
      : (
        <ul>
        {data.map((item) => (
          <li key={item.uuid} className="flex w-100 items-center pl-8 mb-5 text-xl">
            {handleCheckboxChange && (
              <input
                type="checkbox"
                id={`item-${item.uuid}`}
                checked={checkedItems.includes(item.uuid)}
                onChange={() => handleCheckboxChange(item.uuid)}
                className="h-6 w-6 mr-5 text-search-bar focus:ring-search-bar border-gray-300 rounded"
              />
            )}
            {handleRadioChange && (
              <input
                type="radio"
                id={`item-${item.id}`}
                name="radioGroup"
                value={item.id}
                checked={selectedValue === item.id}
                onChange={() => handleRadioChange(item.id)}
                className="h-6 w-6 mr-5 text-search-bar focus:ring-search-bar border-gray-300 rounded"
              />
            )}
            <label htmlFor={`item-${item.id}`}>{item.name}</label>
          </li>
        ))}
      </ul>
      )}
    </div>
  );
};

export default DataDisplay;
