// HamburgerMenu.js
import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useMenuContext } from "../context/MenuContext";

const HamburgerMenu = () => {
  const { isMenuOpen, toggleMenu } = useMenuContext();

  return (
    <button className="hamburger-menu pt-4" onClick={toggleMenu}>
      {isMenuOpen ? (
        <FaBars className="text-white text-2xl cursor-pointer" />
      ) : (
        <FaTimes className="text-white text-2xl cursor-pointer" />
      )}
    </button>
  );
};

export default HamburgerMenu;
