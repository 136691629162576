import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useSearch } from '../../context/searchContext';

const SearchBar = () => {
  const [value, setValue] = useState('');
  const { searchTerm, setSearchTerm } = useSearch();
  const options = ['Everything','Organization', 'Experiences', 'MassAssets'];
  let debounceTimer;
  const navigate=useNavigate();

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);

   
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
     
    // Clear the previous timeout
    clearTimeout(debounceTimer);

    // Set a new timeout for debouncing
    debounceTimer = setTimeout(() => {
      console.log('Performing search with:', searchTerm);
      console.log('option value search with:', value);
      setSearchTerm(searchTerm);
     
    if (value === 'Organization') {
     
      navigate(`/dashboard/tenant`); // Update the path as needed
    }
    else if (value==='MassAssets'){
      navigate(`/dashboard/create-mass-assets`)
    }
      // Add your search logic here
    }, 500);
  };

  return (
    <div className="flex items-center rounded-full text-white overflow-hidden bg-search-bar p-2">
      {/* Left Dropdown */}
      <select
        value={value}
        onChange={handleOptionChange} 
        className="text-cyan bg-search-bar  py-1 px-2 rounded-l focus:outline-none focus:border-none"
      >
        {options.map((option) => (
          <option key={option} value={option} className="text-white">{option}</option>
        ))}
      </select>
      
      {/* Middle Search Text */}
      <input
        type="text"
        placeholder="Search..."
        className="flex-grow px-2 py-1 bg-search-bar text-white focus:outline-none focus:border-none focus:ring focus:ring-cyan"
        onChange={handleSearchChange}
     />
      
      {/* Right Search Icon */}
      <button type="button" className="text-white text-search-icon p-2 rounded-r">
        <AiOutlineSearch />
      </button>
    </div>
  );
};

export default SearchBar;
