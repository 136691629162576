// AssetTable.js
import React from 'react';

const AssetTable = ({ assets}) => {

  return (
    <table className="table-auto w-full border-collapse border border-gray-800">
      <thead>
        <tr className="bg-gray-700 text-white text-left">
          <th className="px-4 py-2">Asset Name</th>
          <th className="px-4 py-2">Status</th>
        </tr>
      </thead>
      <tbody>
        {assets.filter(asset => !asset.file_name.endsWith('.csv')).map((asset, index) => (
          <tr key={asset.id} className={(index % 2 === 0) ? 'bg-gray-800 text-white' : 'bg-gray-900 text-white'}>
            <td className="px-4 py-2">{asset.file_name}</td>
            <td className="px-4 py-2">{asset.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AssetTable;
