import React, { useEffect, useState } from "react";
import { api2,setAccessToken } from "../api/axiosConfig";
import toast from "react-hot-toast";
import { BsGrid3X3, BsListUl } from 'react-icons/bs';
import DataDisplay from "../components/Util/DataDisplay";

function PlatformExperience() {
  const [experiences, setExperiences] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isGrid, setIsGrid] = useState(false);
  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"));
    // Fetch Experiences list
    api2
      .post("/tenant/listPlatformResource")
      .then((response) => {
        setExperiences(response.data.data.platform_experiences);
        console.log(experiences);
      })
      .catch((error) => {
        console.error("Error fetching Experiences:", error);
      });

    // Fetch organizations list
    api2
      .post("/tenant/list")
      .then((response) => {
        setOrganizations(response.data.data.tenants);
        console.log(organizations);
      })
      .catch((error) => {
        console.error("Error fetching organizations:", error);
      });
  }, []);

  const handleRadioChange = (itemId) => {
    if (selectedValue === itemId) {
      // If the same radio button is clicked again, unselect it
      setSelectedValue(null);
    } else {
      // Otherwise, select the clicked radio button
      setSelectedValue(itemId);
    }
  };
  const handleCheckboxChange = (itemId) => {
    const isChecked = checkedItems.includes(itemId);

    if (isChecked) {
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
    } else {
      setCheckedItems([...checkedItems, itemId]);
    }
  };
  const toggleGridView = () => {
    setIsGrid(!isGrid);
  }; 
  const addExperiencesToOrganization = () => {
    const payload = {
      platform_assets: [],
      slug: selectedValue,
      platform_experiences: checkedItems,
    };
    api2
      .post("/tenant/upsertPlatformResource", payload)
      .then((response) => {
        if (response.data.message === "success") {
          toast.success("Experiences successfully added to organization");
        }
        console.log(response.data);
        setCheckedItems([]);
        setSelectedValue(null);
      })
      .catch((error) => {
        console.error("Error fetching organizations:", error);
      });
  };
  const reloadFromS3 = () => {
    api2
      .post("/platformExperience/reloadFromS3")
      .then((response) => {
        if (response.data.message === "Successfully reload") {
          toast.success("Experiences successfully reload");
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching organizations:", error);
      });
  };
  return (
    <>
      <div className="flex flex-col lg:flex-row md:flex-row p-8 mt-5  text-center text-white itms-center justify-between">
        <h1 className="text-3xl mb-4 lg:mb-0">Platform Experiences</h1>
        <div className="flex flex-col lg:flex-row md:flex-row">
          <button
            className="bg-default-button mb-4 lg:mb-0 text-white py-2 px-4 rounded mr-4"
            onClick={addExperiencesToOrganization}
          >
            Add Experiences to Organization
          </button>
          <button
            className="bg-default-button  text-white py-2 px-4 rounded mr-4"
            onClick={reloadFromS3}
          >
            Reload From S3
          </button>
        </div>
      </div>
      <div className="flex justify-end mr-12">
      <button onClick={toggleGridView}>
        {isGrid ? (<button onClick={toggleGridView} className="flex items-center space-x-2 p-2 border rounded bg-search-bar hover:bg-right-panel">
  <BsListUl className="text-3xl text-white" />
 
</button>) : (<button onClick={toggleGridView} className="flex items-center space-x-2 p-2 border rounded bg-search-bar hover:bg-right-panel">
  <BsGrid3X3 className="text-3xl text-white" />
 
</button>)}
      </button>
      </div>
      <div className="flex flex-col md:flex-row text-white mt-10 ml-10">
        <div className=" w-full md:w-3/6">
          <h2 className="text-xl  mb-4 text-logo-color">
             Available Platform Experiences
          </h2>
          <DataDisplay 
      data={experiences} 
      isGrid={isGrid} // Pass grid view state here
      handleCheckboxChange={handleCheckboxChange}
      checkedItems={checkedItems}
      selectedValue={null} />
        </div>
        <div className="w-full mt-10 md:mt-0 md:w-3/6">
          <h2 className="text-xl mb-4 text-logo-color">Organizations</h2>
          <DataDisplay data={organizations}
      isGrid={isGrid} // Pass grid view state here
      handleRadioChange={handleRadioChange}
      selectedValue={selectedValue}
      />
        </div>
      </div>
    </>
  );
}

export default PlatformExperience;
