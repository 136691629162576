// AccordionItem.js
import React, { useEffect, useState } from "react";
import AssetTable from "./AssetTable";

const AccordionItem = ({ job ,job_type}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [jobTitle, setJobTitle]=useState('');
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    console.log(job.upload_status);
  };
  useEffect(()=>{
   if(job.is_scheduled===false){
    const createdAtDate = new Date(job.created_at).toLocaleDateString();
    const createdAtTime = new Date(job.created_at).toLocaleTimeString();
    const dateTime=`Crated At: ${createdAtDate} ${createdAtTime}`;
    const tittle=`Job Id: ${job.job_id} | File: ${job.uploadedFile_details.filename} ${job.uploadedFile_details.filesize_mb} MB | ${dateTime}  | Created By: ${job.created_by_name} `
    setJobTitle(tittle);
  }
   else{
    const createdAtDate = new Date(job.scheduled_time).toLocaleDateString();
    const createdAtTime = new Date(job.scheduled_time).toLocaleTimeString();
    const dateTime=`Scheduled At: ${createdAtDate} ${createdAtTime}`;
    const tittle=`Job Id: ${job.job_id} | File: ${job.uploadedFile_details.filename} ${job.uploadedFile_details.filesize_mb} MB | ${dateTime}  | Scheduled By: ${job.created_by_name} `
    setJobTitle(tittle);
   }
  },[job,job_type])
 

  return (
    <div className="my-4">
      <div
        className={`cursor-pointer bg-gray-800 text-white p-4 rounded-md transition duration-300 ease-in-out transform  ${
          isOpen ? "mb-2" : ""
        }`}
        onClick={toggleAccordion}
      >
        <div className="flex justify-between">
          <span className="font-semibold text-cyan">{jobTitle}
            {/* Job Id:&nbsp;{job.job_id} &nbsp;|&nbsp; File: &nbsp;
            {job.uploadedFile_details.filename}{" "}
            {job.uploadedFile_details.filesize_mb}MB &nbsp;|&nbsp; 
            &nbsp;{dateTime} &nbsp;|&nbsp; Created By: &nbsp;
            {job.created_by_name}{" "} */}
          </span>
          <span className="font-semibold text-green-700">
            Status:&nbsp;{job.job_status}
          </span>
        </div>
      </div>
      <div
  className={`transition-all duration-300 ease-in-out max-h-0 overflow-hidden ${
    isOpen ? "max-h-screen" : ""
  }`}
>
  {job.status !== "Processing" ? (
    <AssetTable assets={job.uploadedFiles_status} />
  ) : (
    <div className="p-8 text-white text-center bg-gray-800">
      <p>The assets upload has been processed</p>
    </div>
  )}
</div>

    </div>
  );
};

export default AccordionItem;
