import React, { useState, useEffect, useCallback } from 'react';
import AccordianItem from "../Accordian/AccordianItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiFilter } from 'react-icons/bi';
import { useSearch } from "../../../context/searchContext";

const FilterBar = ({ jobsData, job_type }) => {
  const { searchTerm } = useSearch();
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    status: "",
    filename: "",
    created_by_name: ""
  });

  useEffect(() => {
    const isSearchTermAvailable = !!searchTerm;

    if (isSearchTermAvailable) {
      const newFilteredData = jobsData.filter(job => {
        return (
          job.job_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.uploadedFile_details.filename.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredJobs(newFilteredData);
    } else {
      setFilteredJobs(jobsData);
    }
  }, [searchTerm, jobsData]);

  const applyFilters = useCallback(() => {
    let filteredJobs = jobsData;

    // Apply date range filter
    if (filters.startDate && filters.endDate) {
      filteredJobs = filteredJobs.filter((job) => {
        // Parse the job's created_at date string into a Date object
        const jobDate = new Date(job.created_at);

        // Extract day, month, and year components
        const jobDay = jobDate.getDate();
        const jobMonth = jobDate.getMonth() + 1; // Months are zero-based
        const jobYear = jobDate.getFullYear();

        // Convert the filters' start and end dates into Date objects
        const startDate = new Date(filters.startDate);
        const endDate = new Date(filters.endDate);

        // Check if the job's date is within the range of the filters
        return (
          (jobDay === startDate.getDate() &&
            jobMonth === startDate.getMonth() + 1 &&
            jobYear === startDate.getFullYear()) ||
          (jobDay === endDate.getDate() &&
            jobMonth === endDate.getMonth() + 1 &&
            jobYear === endDate.getFullYear()) ||
          (jobDate > startDate && jobDate < endDate)
        );
      });
    }

    // Apply status filter
    if (filters.status !== "") {
      filteredJobs = filteredJobs.filter(
        (job) => job.job_status === filters.status
      );
    }

    // Apply filename filter
    if (filters.filename !== "") {
      filteredJobs = filteredJobs.filter((job) =>
        job.uploadedFile_details.filename.includes(filters.filename)
      );
    }

    // Apply created_by_name filter
    if (filters.created_by_name !== "") {
      filteredJobs = filteredJobs.filter((job) =>
        job.created_by_name.toLowerCase().includes(filters.created_by_name.toLowerCase())
      );
    }

    setFilteredJobs(filteredJobs);
  }, [jobsData, filters.startDate, filters.endDate, filters.status, filters.filename, filters.created_by_name]);

  useEffect(() => {
    applyFilters();
  }, [filters, applyFilters, jobsData]);

  return (
    <>
      <div className=" mx-auto p-6 flex justify-end">
      <div className="flex flex-col space-y-4 mb-4 xl:flex-row xl:space-y-0 xl:space-x-4">
      <div className='flex justify-center mb-4'><BiFilter className="text-gray-500 text-2xl" />
        <DatePicker
    selected={filters.startDate}
    onChange={(date) => setFilters({ ...filters, startDate: date })}
    selectsStart
    startDate={filters.startDate}
    endDate={filters.endDate}
    placeholderText="Start Date"
    className="form-input px-4 py-2 mr-4 border rounded focus:outline-none focus:border-blue-500 text-black"
  />
  <DatePicker
    selected={filters.endDate}
    onChange={(date) => setFilters({ ...filters, endDate: date })}
    selectsEnd
    startDate={filters.startDate}
    endDate={filters.endDate}
    placeholderText="End Date"
    className="form-input px-4 py-2 border rounded focus:outline-none focus:border-blue-500 text-black"
  />
  </div>
  <div className='flex justify-center '>
  <select
    id="status"
    className="form-select px-4 py-2 mb-4 border rounded focus:outline-none focus:border-blue-500 text-black"
    value={filters.status}
    onChange={(e) => setFilters({ ...filters, status: e.target.value })}
  >
    <option value="">All Status</option>
    <option value="created">created</option>
    <option value="processing">processing</option>
    <option value="completed">completed</option>
    <option value="failed">failed</option>
    <option value="rejected">rejected</option>
  </select>
  <input
    type="text"
    id="created_by_name"
    className="form-input px-4 py-2 mb-4 ml-4 border rounded focus:outline-none focus:border-blue-500 text-black"
    placeholder="Enter created by name"
    value={filters.created_by_name}
    onChange={(e) =>
      setFilters({ ...filters, created_by_name: e.target.value })
    }
  />
  </div>
</div>

      </div>
      <div>
        {filteredJobs.length > 0 ? (
          <div>
            {filteredJobs.map((job) => (
              <AccordianItem key={job.job_id} job={job} job_type={job_type} />
            ))}
          </div>
        ) : (
          <p>No results found.</p>
        )}
      </div>
    </>
  );
};

export default FilterBar;
