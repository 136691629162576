// axiosConfig.js

import axios from 'axios';

const token=  localStorage.getItem("accessToken");
// Instance for API 1
const api1 = axios.create({
  baseURL: 'https://api.tstvixr.in/v2/tenant',
  timeout: 5000, // Set a timeout for requests (optional)
  headers: {
    'Authorization':`Bearer:${token}`,
    'Content-Type': 'application/json',
  },
});

// Create a custom Axios instance with your desired configuration
const api2 = axios.create({
  baseURL: 'https://api.devvixr.in/v2/tenant-maker', // Set your API base URL
  timeout: 5000, // Set a timeout for requests (optional)
  headers: {
    'Content-Type': 'application/json',
  },
});
const setAccessToken = (token) => {
  api2.defaults.headers.common['Authorization'] = `Bearer:${token}`;
};

// Configuration for APIs with the login base URL
const apiAxiosForLogin = axios.create({
  baseURL: "https://api.devvixr.in/v2/tenant-maker",
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export  {api1,api2,apiAxiosForLogin,setAccessToken};
