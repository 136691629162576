// DragDropUpload.js

import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadZip from "./UploadZip";
import Dropzone from "./Dropzone";

const DragDropUpload = ({ handleUploadedFiles, handleMultipleUploadFiles ,isLoading,startLoading,selectedFiles}) => {
 
  const [files, setFiles] = useState([]);
  useEffect(()=>{
  if(selectedFiles.length===0){
    setFiles([]);
  }
  },[selectedFiles])
  const handleFilesDrop = (files) => {
   
    // Handle the dropped files here
    console.log(files);
    setFiles(Array.from(files));
    handleMultipleUploadFiles(Array.from(files));
  };

  return (
    <div className="flex flex-col mt-10 rounded-lg text-white bg-left-panel p-8 border-gray-300  shadow-lg text-gray h-full">
      <div className="flex-1  h-5/10">
        <UploadZip
          handleUploadedFiles={handleUploadedFiles}
          selectedFilesForUpload={files}
          isLoading={isLoading}
        />
        <div className="flex flex-col  space-y-4 mb-10">
          <ToastContainer />
        </div>
      </div>
      <div className="flex-1 bg-blue-100 rounded-md h-3/10 text-black">
        <Dropzone onFilesDrop={handleFilesDrop} startLoading={startLoading} />
      </div>
    </div>
  );
};

export default DragDropUpload;
