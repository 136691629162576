// ScheduledUpload.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format,parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useProgressContext } from '../../context/ProgressContext';

const ScheduledUpload = ({filesForUpload,clearUploadedFiles,stopLoading}) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const { setProgress } = useProgressContext();
 // const [isoDateTime, setIsoDateTime]=useState('');
  const [scheduledJobs,setScheduledJobs]=useState([]);
  const token=  localStorage.getItem("accessToken");
  const currentDate = new Date().toISOString().split('T')[0]; // Get current date in "yyyy-mm-dd" format
  useEffect(()=>{
    setScheduledJobs(filesForUpload);
  },[filesForUpload])
  const handleDateChange = (dateString) => {
 // const dateString = "Mon Jan 29 2024 12:15:55 GMT+0530 (India Standard Time)";
 // const dateObject = new Date(dateString);
 // const isoDateString = dateObject.toISOString();
    setSelectedDate(dateString);
    console.log(dateString);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
    console.log(selectedTime);
  };
const apiCall=async(isoDateTime)=>{
  const apiUrl = ' https://api.devvixr.in/v2/tenant/mass-asset/asset/uploadAsset';
  // Create FormData to send file data
  const formData = new FormData();
  // Check if the array is empty
  const isArrayEmpty = filesForUpload.length === 0;
  if(isArrayEmpty){
   alert("Please select file for upload");
  }else{
    filesForUpload.map(file=>{
      return formData.append('file', file);
     })
     formData.append("scheduled_time",isoDateTime);
    // Make a POST request to the API endpoint using Axios
     const response = await axios.post(apiUrl, formData, {
       headers: {
         'Content-Type': 'multipart/form-data',
         Authorization:`bearer:${token}`
       },
       onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         const percentage = Math.round((loaded / total) * 100);
         setProgress(percentage);
         
       },
     });
     if(response.status===200){
      console.log(response.data.data);
      toast.success(`File uploaded scheduled successfully.`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
     // stopLoading();
     setProgress(0);
      clearUploadedFiles();
    }else{
       alert("file upload fail");
    }
     
  }
  
}
  const handleUpload = async() => {
    // Implement your upload logic here
    if (selectedDate && selectedTime) {
      const isoDateTime = new Date(`${selectedDate}T${selectedTime}`).toISOString();
      console.log('ISO Format:', isoDateTime);
     // setIsoDateTime(isoDateTime);
       apiCall(isoDateTime);
    
    } else {
      alert('Please select both date and time.');
    }

   
     
 
    
   
    
   
   
  };

  return (
    <div className=" flex justify-between bg-gray-900 text-white mr-4 ">
      <label className="text-white mr-4">
        <span className='mr-4'>Select Date:</span>
        <input
          type="date"
          className="bg-gray text-black p-2 rounded border border-gray-700"
          value={selectedDate ? format(parseISO(selectedDate), 'yyyy-MM-dd') : ''}
          onChange={(e) => handleDateChange(e.target.value)}
          min={currentDate}
        />
      </label>
     
      <label className="text-white mr-4">
      <span className='mr-4'>Select Time:</span>
        <input
          type="time"
          className="bg-gray text-black p-2 rounded border border-gray-700"
          value={selectedTime}
          onChange={handleTimeChange}
        />
      </label>
      
      <button
        className="bg-indigo-600 text-white p-2 rounded border border-indigo-700 hover:bg-indigo-500"
        onClick={handleUpload}
      >
        Schedule Upload
      </button>
    </div>
  );
};

export default ScheduledUpload;
