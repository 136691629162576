import React, { useCallback, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaPlus } from 'react-icons/fa';

const Dropzone = ({ onFilesDrop ,startLoading}) => {
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const onDrop = useCallback((acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => file.size > 0);

    const newFiles = validFiles.filter(
      (newFile) => !files.some((existingFile) => existingFile.name === newFile.name)
    );

    if (newFiles.length > 0) {
      // setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      // onFilesDrop([...files, ...newFiles]);
      setFiles(acceptedFiles);
      onFilesDrop(acceptedFiles);
    }
  }, [onFilesDrop, files]);



  const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true });

  const handleClickPlus = () => {
   
    //startLoading();
    
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  return (
    <div
      {...getRootProps({
        className: 'relative bg-gray-800 border-dashed border-2 border-gray-600 p-8 rounded-lg text-center',
        style: { minHeight: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
      })}
    >
      <input {...getInputProps()} ref={fileInputRef} />
      <p className="text-white">
        Drag and drop files here
      </p>
   
      <div className="absolute top-0 right-0 p-2" style={{ transform: 'translateY(-50%)' }}>
        <button
          className="bg-blue-500 p-3 rounded-full cursor-pointer"
          onClick={handleClickPlus}
        >
          <FaPlus className="text-white text-lg" />
        </button>
      </div>
    </div>
  );
};

export default Dropzone;
