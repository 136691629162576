import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash } from 'react-icons/fa';
import { useProgressContext } from '../../context/ProgressContext';

function UploadZip({handleUploadedFiles,selectedFilesForUpload,isLoading}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
 // const [progress, setProgress] = useState(10);
  const[progressBarShow,setProgressBarShow]=useState(true);
  const { progress,setProgress } = useProgressContext();
  const token=  localStorage.getItem("accessToken");
  
  useEffect(()=>{
   // console.log(selectedFilesForUpload);
  setSelectedFiles(selectedFilesForUpload);
  setProgressBarShow(isLoading);
  //  let interval;

  //   const increaseProgress = () => {
  //     setProgress((prevProgress) => {
  //       const newProgress = prevProgress + 10;

  //       if (newProgress >= 100) {
  //         clearInterval(interval);
  //         setProgressBarShow(false);
  //         setProgress(10);
  //       }

  //       return newProgress;
  //     });
  //   };

  //   if (!isLoading) {
  //     // Start increasing progress by 10 every second until it reaches 100
  //     interval = setInterval(increaseProgress, 1000);
  //   }

  //   // Cleanup the interval on component unmount
  //   return () => clearInterval(interval);
   
  },[selectedFilesForUpload,isLoading]);
 
  useEffect(()=>{
   
     console.log(`selected files are ${selectedFiles}`);
    
   },[selectedFiles])
  const handleUpload = async () => {
    try {
      if (selectedFiles.length===0) {
        toast.error(`No file selected for upload.`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 3000,
        });
        
      }
     else{
      setUploading(true);
      const apiUrl = ' https://api.devvixr.in/v2/tenant/mass-asset/asset/uploadAsset';
      // Create FormData to send file data
      const formData = new FormData();
      selectedFiles.map(file=>{
       return formData.append('file', file);
      })
     // Make a POST request to the API endpoint using Axios
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:`bearer:${token}`
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.round((loaded / total) * 100);
          setProgress(percentage);
          
        },
      });
         // Check if the upload was successful
         if (response.status === 200) {
        
          console.log('File uploaded successfully.');
          toast.success(`File uploaded successfully.`, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000,
          });
          setTimeout(() => {
           setProgressBarShow(false);
           setProgress(10);
           setSelectedFiles([]);
            toast.success(`Asset Creation Processed!!`, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
          }, 3000);
          console.log(response.data.data);
          handleUploadedFiles(response.data.data);
          // Additional handling if needed
        } else {
          console.error('Error uploading file:', response.statusText);
        }
     }
     
     
   
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setUploading(false);
    }
  };
  const handleRemoveFile = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };
  const handleRemoveAllFiles=()=>{
    setSelectedFiles([]);
  }
  return (
    <div>
      <div className='flex justify-between'>
        <h1 className="text-2xl font-bold mb-4">Upload Zip File</h1>
      <button onClick={handleUpload} disabled={uploading || !selectedFilesForUpload}
      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-4"
      >
        {uploading ? `uploading ${progress}%` : 'Upload ZIP File'}
      </button>
      </div>
      <div>
          {progressBarShow && selectedFiles.map((file,index)=>(<div key={file.name}>
            <div className='flex'>
            <p className='mt-2'>{file.name}</p>
            <button className="text-xl ml-2 mt-1 text-error-color" onClick={() => handleRemoveFile(index)}><FaTrash /></button></div>
          <div  className="flex h-5 rounded-lg  mt-4 overflow-hidden text-xs bg-white">
        <div
          style={{ width: `${progress}%` }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
        ></div>
     
      </div>  </div>))}</div>
     
      <ToastContainer/>
    </div>
  );
}

export default UploadZip;
