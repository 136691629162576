// src/components/Tabs.js
import React, { useEffect, useState } from 'react';
import FilterBar from './Filter/FilterBar';

const Tabs = ({jobsData}) => {
  const [activeTab, setActiveTab] = useState('scheduled');
  const [scheduledJobs,setScheduledJobs]=useState([]);
  const [submittedJobs,setSubmittedJobs]=useState([]);
useEffect(()=>{
  // Filter jobs based on is_scheduled value
  setScheduledJobs(jobsData.filter((job) => job.is_scheduled));
  setSubmittedJobs(jobsData.filter((job) => !job.is_scheduled));
   //setJobsUploaded(filteredJobs);
},[jobsData]);
  const handleTabChange = (tab) => {
    
    setActiveTab(tab);
  };

  return (
    <div className="bg-gray-800 text-white p-4 mt-4">
      <div className="flex space-x-0">
        <button
          onClick={() => handleTabChange('scheduled')}
          className={`px-6 py-4 m-0 ${activeTab === 'scheduled' ? 'bg-default-button' : 'bg-gray-600'} rounded-tl-lg rounded-tr-lg `}
        >
          Scheduled Jobs
        </button>
        <button
          onClick={() => handleTabChange('submitted')}
          className={`px-4 py-2 m-0 ${activeTab === 'submitted' ? 'bg-default-button' : 'bg-gray-600'} rounded-tl-lg rounded-tr-lg`}
        >
          Submitted Jobs
        </button>
      </div>
      <div className="mt-4">
        {activeTab === 'scheduled' ? (
         <FilterBar jobsData={scheduledJobs} job_type="scheduled" />
        ) : (
            <FilterBar jobsData={submittedJobs} job_type="submitted" />
        )}
      </div>
    </div>
  );
};

export default Tabs;
