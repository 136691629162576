import LoginPage from "./Pages/LoginPage";
import DashboardPage from "./Pages/DashboardPage";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import TenantPage from "./Pages/TenantPage";
import PlatformAssets from "./Pages/PlatformAssets";
import PlatformExperience from "./Pages/PlatformExperience";
import ProfilePage from "./Pages/ProfilePage";
import Privacypolicy from "./Pages/PrivacyPolicy";
import SwitchOrganization from "./Pages/SwitchOrganization";
import Contacts from "./Pages/Contacts";
import LoginForm from "./components/Login/LoginForm";
import SignupForm from "./components/Login/SignupForm";
import SignupFormWithAddOrganization from "./components/Login/SignupSubForms/SignupFormWithAddOrganization";
import SignupFormWithOrganizationDetail from "./components/Login/SignupSubForms/SignupFormWithOrganizationDetail";
import SignupFormWithOrganizationTheme from "./components/Login/SignupSubForms/SignupFormWithOrganizationTheme";
import SignupFormFinishedMessage from "./components/Login/SignupSubForms/SignupFormFinishedMessage";
import SignupFormWithOrganizationPlan from "./components/Login/SignupSubForms/SignupFormWithOrganizationPlan";
import TenantDetailPage from "./Pages/TenantDetailPage";
import OrganizationTheme from "./components/Organization/OrganizationTheme";
import OrganizationProfile from "./components/Organization/OrganizationProfile";
import OrganizationContstraints from "./components/Organization/OrganizationContstraints";
import OrganizationResource from "./components/Organization/OrganizationResource";
import EmailPasswordLogin from "./components/Login/EmailPasswordLogin";
import MongoDataManager from "./Pages/MongoDataManage";
import MoreContent from "./Pages/MoreContent";
import ForgotPassword from "./components/Login/ForgotPassword";

import { Toaster } from "react-hot-toast";

import AnalyticsDashboard from "./Pages/Analytics/AnalyticsDashbord";
import MassAssets from "./Pages/MassAssets";

function App() {
  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Router>
        <div className="h-screen  bg-right-panel">
          <Routes>
            <Route path="/" element={<LoginPage />}>
              <Route path="/" element={<LoginForm />}></Route>
              <Route
                path="/login-with-email"
                element={<EmailPasswordLogin />}
              ></Route>
              <Route
                path="/forgot-password"
                element={<ForgotPassword />}
              ></Route>
              <Route path="/signup" exact element={<SignupForm />}></Route>
              <Route
                path="/signup-add-organization-name"
                element={<SignupFormWithAddOrganization />}
              ></Route>
              <Route
                path="/signup-add-organization-detail"
                element={<SignupFormWithOrganizationDetail />}
              ></Route>
              <Route
                path="/signup-add-organization-theme"
                element={<SignupFormWithOrganizationTheme />}
              ></Route>
              <Route
                path="/signup-add-organization-plan"
                element={<SignupFormWithOrganizationPlan />}
              ></Route>
              <Route
                path="/signup-finished"
                element={<SignupFormFinishedMessage />}
              ></Route>
            </Route>
            <Route path="/dashboard" element={<DashboardPage />}>
              <Route path="/dashboard/tenant"  element={<TenantPage />}></Route>
              <Route
                path="/dashboard/mongoData"
                element={<MongoDataManager />}
              ></Route>
              <Route
                path="/dashboard/tenant/:slug"
                element={<TenantDetailPage />}
              >
                <Route
                  path="/dashboard/tenant/:slug/profile"
                  element={<OrganizationProfile />}
                ></Route>
                <Route
                  path="/dashboard/tenant/:slug/theme"
                  element={<OrganizationTheme />}
                ></Route>
                <Route
                  path="/dashboard/tenant/:slug/constraints"
                  element={<OrganizationContstraints />}
                ></Route>
                <Route
                  path="/dashboard/tenant/:slug/resource"
                  element={<OrganizationResource />}
                ></Route>
              </Route>

              <Route
                path="/dashboard/platform-asset"
                element={<PlatformAssets />}
              ></Route>
               <Route
                path="/dashboard/create-mass-assets"
                element={<MassAssets />}
              ></Route>
              <Route
                path="/dashboard/platform-experience"
                element={<PlatformExperience />}
              ></Route>

              <Route
                path="/dashboard/user-profile"
                element={<ProfilePage />}
              ></Route>
              <Route
                path="/dashboard/more-content"
                element={<MoreContent />}
              ></Route>
              <Route
                path="/dashboard/analytics"
                element={<AnalyticsDashboard />}
              ></Route>
            </Route>
            <Route path="/privacy-policy" element={<Privacypolicy />}></Route>
            <Route
              path="/switch-organization"
              element={<SwitchOrganization />}
            ></Route>
            <Route path="/contacts" element={<Contacts />}></Route>
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
