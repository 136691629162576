import React from 'react';
import PropTypes from 'prop-types';
const LogoutModal = ({ isOpen, onClose, onLogout }) => {
  return (
    <div className={`fixed z-50 inset-0 ${isOpen ? '' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-screen">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="text-xl z-10 bg-left-panel text-white p-10 rounded-md ">
          <p>Are you sure you want to logout?</p>
          <div className="flex justify-center mt-4">
            <button
              onClick={onLogout}
              className="bg-default-button text-white px-4 py-2 rounded-md mr-4 mt-4"
            >
              Logout
            </button>
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md mt-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
LogoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
export default LogoutModal;
